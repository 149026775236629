import { ReactNode, useMemo } from 'react';
import { useFadeInStyles, useProjectContext } from '~/hooks';
import { Icon, Popup, Label } from 'semantic-ui-react';
import MainContainer from './MainContainer';
import DataroomCounts from './DataroomCounts';
import Container from '../Container';
import PageHeader from '../PageHeader';
import Button from '../Button';
import { downloadProjectExcel } from '~/utils';
import { PageHeaderProvider } from '../PageHeader/PageHeaderProvider';
import { ContractType, DealStates, PricingType, ProjectInterconnectionTypeCodes, ProjectStatus } from '~/constants';
import { useLocation } from 'react-router-dom';
import ProjectSubmissionButtons from './components/ProjectSubmissionButtons';
import PageLoader from '../PageLoader';
import { useRequiredResponseCountsQuery } from '~/requests/projects/useRequiredResponseCounts';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { useProjectTeamQuery } from '~/requests/team';
import { getDataroomCounts } from '~/utils/calculators/dataroomCounts';
import { useDataroomQuery } from '~/requests/dataroom/useDataroomQuery';
import AdditionalActions from '../AdditionalActions';
import ArchiveAction from '../AdditionalActions/ArchiveAction';
import { DeploymentTypes } from '~/constants/deployment-type';
import ProjectCustomerProposal from '../ProjectCustomerProposal';

const ProjectPageContent = function ({ children, showDataroomTab }: { children: ReactNode; showDataroomTab?: boolean}) {
  const { project, formattedProject, projectCosts, projectState, projectDisplayValues } = useProjectContext();
  const { permissions } = usePermissions();

  const showCustomerProposalOption = permissions?.hasProjectDeveloperAccess
    && project?.deploymentType === DeploymentTypes.CI 
    && project?.contractType === ContractType.PPA 
    && project?.pricingType === PricingType.OFFTAKE
    && project?.interconnectionType === ProjectInterconnectionTypeCodes.BEHIND_METER;

  const { data: requiredResponseCountsData, isFetching } = useRequiredResponseCountsQuery();
  const requiredResponseCounts = requiredResponseCountsData?.reduce((acc: number, curr: any) => acc + curr.count, 0) ?? 0;
  
  const { pathname } = useLocation();
  const showSubmissionOptions =
    permissions?.hasProjectDeveloperAccess &&
    projectState?.dealState !== undefined && projectState.dealState < DealStates.IN_REVIEW &&
    pathname === `/project/${project.id}/review`;
  
  return (
    <PageHeaderProvider>
      <Container style={useFadeInStyles()}>
        <PageHeader 
          project={project}
          navTabs={[{
            path: `/project/${project.id}/review`,
            content: 'Summary',
          }, {
            path: `/project/${project.id}/wizard`,
            content: 'Data',
          }, 
          showDataroomTab && project.interconnectionType ? {
            path: `/project/${project.id}/dataroom`,
            content: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>Documents</div>
                <DataroomCounts />
              </div>
            ),
          } : showDataroomTab && !project.interconnectionType ? {
            content: (
              <Popup
                inverted
                position="bottom center"
                trigger={<div style={{ opacity: 0.5 }}>Documents</div>}
                content={'Please finish the initial setup of your project to access the documents area.'}
              />
            ),
            style: { cursor: 'default' }
          } : null, {
            path: `/project/${project.id}/messages`,
            content: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>Messages</div>
                {isFetching ? null : (
                  pathname !== `/project/${project.id}/messages` && requiredResponseCounts > 0 && (
                    <Label circular color='red' style={{ marginLeft: 'var(--x-small)' }}>
                      {requiredResponseCounts}  
                    </Label>
                  )
                )}
              </div>
            ),
          }, permissions.canManageProjectTeam && {
            path: `/project/${project.id}/team`,
            content: 'Team Management'
          }]}
          navContent={
            <div 
              style={{
                display: 'flex',
                gap: 'var(--x-small)',
                position: 'absolute', 
                right: 0, 
                top: 0 
              }}>
                {showSubmissionOptions && !project?.isArchived ? <ProjectSubmissionButtons /> : null}
                <Button style={{}} onClick={() => downloadProjectExcel(formattedProject, projectCosts, projectDisplayValues, permissions)} outlined>
                  <Icon name='arrow circle down' />
                  Download project data file
                </Button>
                <AdditionalActions>
                  {permissions.hasArchiveActions && <ArchiveAction />}
                  {showCustomerProposalOption && <ProjectCustomerProposal />}
                </AdditionalActions>
            </div>
          }
        />
        <MainContainer>{children}</MainContainer>
      </Container>
    </PageHeaderProvider>
  );
};

export default function ProjectPage({ children }: { children: ReactNode}) {
  const { project, fetchStatus } = useProjectContext();
  const { status: permissionStatus, permissions } = usePermissions();
  const { status: teamStatus } = useProjectTeamQuery();
  const { status: dataroomStatus, data: dataroom } = useDataroomQuery({
    onError(err) {
      console.error(err);
      // Do nothing. This can happen if user does not have dataroom access
    }
  });

  const isInvestor = permissions?.hasProjectInvestorAccess && !permissions.isAdmin;
  const dataroomCounts = useMemo(() => {
    return dataroom ? getDataroomCounts(dataroom.documents) : null;
  }, [dataroom]);

  const showDataroomTab = permissions?.canReadDataroom && !(isInvestor && project.status !== ProjectStatus.IN_DILIGENCE && !dataroomCounts?.uploaded);

  if (fetchStatus === 'loading' || permissionStatus === 'loading' || teamStatus === 'loading' || dataroomStatus === 'loading') {
    return <div style={{ width: '100%', position: 'relative', height: '100vh' }}><PageLoader /></div>;
  } else if (project?.id) {
    return <ProjectPageContent showDataroomTab={showDataroomTab}>{children}</ProjectPageContent>;
  } else {
    return null;
  }
}
