import axios from '~/utils/axiosInstance';
import { ProjectStatus } from '~/constants';
import { nullifyEmptyStrings } from '~/utils';
import { format, parse } from '~/transforms/project';
import { TeamManagementResponse, TeamMember } from '~/types/teamManagement';
import { Project } from '~/types/project';
import { ApiEvent } from '~/types/ApiEvent';
import { ArchiveResult } from '~/types/ArchiveResult';


function removeLocalUpdatedAt(data: any) {
  delete data.localUpdatedAt;
  return data;
}

export const getProjectResponseRequiredCounts = async function (id: string) {
  const response = await axios.get(`/projects/${id}/response-required-counts`);
  return response.data;
};

export const createProject = async function (data: any = {}) {
  const response = await axios.post('/projects', format(data));
  return parse(response.data);
};

export const fetchProject = async function (id: string) {
  const response = await axios.get(`/projects/${id}`);
  return parse(response.data);
};

export const fetchProjects = async function (params: any = {}) {
  const response = await axios.get('/projects', {params});
  return response.data;
};

export const fetchProjectConfig = async function () {
  const response = await axios.get('/projects/config');
  return response.data;
};

export const submitProject = async function (id: string, data: any) {
  const response = await axios.patch(`/projects/${id}`, nullifyEmptyStrings({...data, status:  ProjectStatus.IN_REVIEW}));
  return parse(response.data);
};

export const updateProject = async function (id: string, data: any, additionalParams: any = {}) {
  const response = await axios.patch(`/projects/${id}`, format({
    ...removeLocalUpdatedAt(nullifyEmptyStrings(data)),
    ...additionalParams
  }));
  return parse(response.data) as Project;
};

export const archiveProject = async function (id: string) {
  const response = await axios.post(`/projects/${id}/archive`);
  return response.data as ArchiveResult;
};

export const unArchiveProject = async function (id: string) {
  const response = await axios.post(`/projects/${id}/unarchive`);
  return response.data as ArchiveResult;
};

export const reactivateProject = async function (id: string) {
  const response = await axios.post(`/projects/${id}/reactivate`);
  return response.data;
};

export const autopricing = async function (id: string, runAsync?: boolean) {
  const response = await axios.post(`/projects/${id}/autopricing${runAsync !== undefined ? `?runAsync=${runAsync}` : ''}`);
  return response.data;
};

export const generateInstantQuote = async function (data: any) {
  const response = await axios.post('/projects/instant-quote', data);
  return parse(response.data);
};

export const createProjectFromPPAQuote = async function (id: string) {
  const response = await axios.post(`/projects/instant-quote/${id}/save`);
  return response.data;
};

export const getProjectPermissionList = async function (id: string) {
  const response = await axios.get(`/projects/${id}/permissions`);
  return response.data ?? {};
};

export const getProjectPermission = async function (id: string, permission: any) {
  const response = await axios.get(`/projects/${id}/permissions/${permission}`);
  return response.data.hasPermission;
};

export const initDnvEnergyRequest = async function (projectId: string) {
  return axios.get(`/projects/${projectId}/dnv`).then(res => res.data as ApiEvent);
};

export const pollAsyncRequestResult = async function (projectId: string, apiEventId: string) {
  const response = await axios.get(`/projects/${projectId}/async-request/${apiEventId}/poll`);
  return response.data as ApiEvent;
};

export const pollDnvResult = async function (projectId: string) {
  const response = await axios.get(`/projects/${projectId}/dnv/poll`);
  return response.data as Project;
};

export const getProjectTeam = async function (id: string) {
  const response = await axios.get(`/projects/${id}/team`);
  return response.data as TeamManagementResponse;
};

export const addProjectTeamMember = async function (projectId: string, userId: string) {
  const response = await axios.post(`/projects/${projectId}/team/users`, { userId });
  return response.data as TeamMember;
};

export const updateProjectTeamMember = async function ({
  id,
  memberId,
  role,
  isActive
}: {
  id: string; 
  memberId: string;
  role?: 'LEAD' | 'MEMBER';
  isActive?: boolean
}) {
  const response = await axios.patch(`/projects/${id}/team/${memberId}`, { role, isActive });
  return response.data;
};

export const inviteExternalProjectTeamMember = async function (projectId: string, email: string) {
  const response = await axios.post(`/projects/${projectId}/team/external-users`, { email });
  return response.data;
};

export const createCustomerProposalRequest = async function (id: string) {
  const response = await axios.post(`/projects/${id}/customer-proposal-request`);
  return response.data;
};