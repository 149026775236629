import { useEffect, useReducer } from 'react';
import { orderBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Grid, Label, Loader } from 'semantic-ui-react';
import { Table } from '~/components';
import { ProjectFilterType, ProjectFilters } from '~/constants';
import { useQueryParamState } from '~/hooks/useQueryParamState';
import { useProjectList } from '~/requests/projects/useProjectList';
import ProjectStatusBadge from './ProjectStatusBadge';
import styled from 'styled-components';
import Link from '~/components/Link';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { format } from 'date-fns';
import { DASHBOARD_QUERY_PARAM_STATE } from '../constants';
import { formatNumber } from '~/utils';

interface DashboardTableProps {
  navHeight?: number;
  itemCreationRowHeight?: number;
  footerHeight?: number;
}

interface TableState {
  column: string;
  data: any[];
  direction: 'asc' | 'desc';
  filter?: ProjectFilterType;
}

interface TableAction {
  type: string;
  data?: any[];
  column?: string;
  filter?: ProjectFilterType;
}

function filterProjects(projects: any[], filter: string) {
  switch (filter) {
    case ProjectFilters.ALL:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => !isArchived && !portfolioIsArchived && statusDisplay !== 'BID_NOT_SELECTED' && statusDisplay !== 'DECLINED_TO_BID');
    case ProjectFilters.DRAFT:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'NOT_SUBMITTED' && !isArchived && !portfolioIsArchived);
    case ProjectFilters.IN_REVIEW:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'IN_REVIEW' && !isArchived && !portfolioIsArchived);
    case ProjectFilters.BIDDING:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'BIDDING' && !isArchived && !portfolioIsArchived);
    case ProjectFilters.BID_REVIEW:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'BID_REVIEW' && !isArchived && !portfolioIsArchived);
    case ProjectFilters.IN_DILIGENCE:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'IN_DILIGENCE' && !isArchived && !portfolioIsArchived);
    case ProjectFilters.CLOSED:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => statusDisplay === 'BID_NOT_SELECTED' || statusDisplay === 'DECLINED_TO_BID' || isArchived || portfolioIsArchived);
    case ProjectFilters.OPEN_ITEMS:
      return projects.filter(({ hasOpenConversation, requiresCustomerProposal, isArchived, portfolioIsArchived }) => (hasOpenConversation || requiresCustomerProposal) && !isArchived && !portfolioIsArchived);
    default:
      return projects;
  }
}

const DefaultSortOrders: { [key: string]: 'asc' | 'desc' } = {
  name: 'asc',
  portfolioName: 'asc',
  investorName: 'asc',
  installerName: 'asc',
  statusDisplay: 'asc',
  location: 'asc',
  size: 'asc',
  bidDeadline: 'desc',
  updatedAt: 'asc'
};

function projectListReducer(state: TableState, action: TableAction): TableState {
  const data = (action.data ?? state.data);

  const column = action.column ?? state.column;

  const sortData = (data: any) => {
    if (typeof data?.[column] !== 'string') {
      return data[column];
    } else if (column === 'location') {
      // Flip state and city so we can prioritize sort by state first
      const [state, city] = data[column]?.split(', ')?.reverse() ?? [];
      if (!state && !city) {
        return '';
      } else {
        return `${state}, ${city ?? ''}`.toLowerCase();
      }
    } else {
      return data?.[column]?.toLowerCase();
    }
  };

  const getSortedData = (data: any[], direction: 'asc' | 'desc') => {
    if (column === 'bidDeadline') {
      // No matter the direction, we want to prioritize projects with bid deadlines at the top.
      // Using a custom .sort instead of lodash orderBy to have more control over sorting
      return data.sort((a, b) => {
        if (!a.bidDeadline && !b.bidDeadline) {
          return 0;
        } else if (!a.bidDeadline) {
          return 1;
        } else if (!b.bidDeadline) {
          return -1;
        } else {
          const aTime = new Date(a.bidDeadline).getTime();
          const bTime = new Date(b.bidDeadline).getTime();
          return direction === 'asc' ? aTime - bTime : bTime - aTime;
        }
      });
    } else if (column === 'statusDisplay') {
     // Sort by statusDisplay first, and if the status is BIDDING sort further by bid deadline
     // For bidding statuses, we want to prioritize projects with bid deadlines at the top.
     return data.sort((a, b) => {
      if (a.statusDisplay === 'BIDDING' && b.statusDisplay === 'BIDDING') {
        if (!a.bidDeadline && !b.bidDeadline) {
          return 0;
        } else if (!a.bidDeadline) {
          return 1;
        } else if (!b.bidDeadline) {
          return -1;
        } else {
          const aTime = new Date(a.bidDeadline).getTime();
          const bTime = new Date(b.bidDeadline).getTime();
          return direction === 'asc' ? aTime - bTime : bTime - aTime;
        }
      } 
      return direction === 'asc' ? a.statusDisplay.localeCompare(b.statusDisplay) : b.statusDisplay.localeCompare(a.statusDisplay);
     }); 
    }

    return orderBy(data, [sortData], [direction]);
  };

  switch (action.type) {
    case 'INIT':
      return {
        column: state.column,
        data: getSortedData(filterProjects(data ?? [], state.filter ?? ProjectFilters.ALL), state.direction),
        direction: state.direction,
      };
    case 'CHANGE_SORT': {
      // If the user clicks the same column, reverse the sort direction
      // Otherwise use the default sort order for the column
      const direction = action.column === state.column 
        ? (state.direction === 'asc' ? 'desc' : 'asc')
        : (DefaultSortOrders[action.column as string] ?? 'asc');
      
      return {
        ...state,
        column,
        data: getSortedData(data, direction),
        direction,
      };
    }
    case 'CHANGE_FILTER':
      if (state.filter === action.filter) {
        return state;
      }
      return {
        ...state,
        filter: action.filter,
        data: getSortedData(filterProjects(data, action.filter as string), state.direction ?? 'desc')
      };
    default:
      throw new Error();
  }
}

const StyledTable = styled(Table)<{ isAdmin: boolean }>`
  ${props => props.isAdmin && `
    table-layout: fixed;
    tbody td {
      white-space: break-spaces;
    }
  `}
`;

const TableLoader = styled(Loader)`
  &&& {
    position: absolute;
    left: 0;
    right: 0;
    margin: 1rem auto 0;
  }
`;
TableLoader.defaultProps = { active: true, size: 'big' };

const EmptyTableMessage = styled.p`
  font-size: 0.875rem;
  color: var(--color-pure-black);
  text-align: center;
  margin-top: var(--2x-large);
`;

export default function DashboardTable({ footerHeight, navHeight, itemCreationRowHeight }: DashboardTableProps) {
  // Determine states based on permissions
  const { permissions } = usePermissions();

  const showInvestorName = permissions.hasDeveloperRole || permissions.isAdmin;
  const showDeveloperName = permissions.hasInvestorRole || permissions.isAdmin;

  // Fetch + cache project list
  const { data: projects, status } = useProjectList({
    showArchived: true
  });

  // Set defaults
  navHeight = navHeight ?? 0;
  itemCreationRowHeight = itemCreationRowHeight ?? 0;
  footerHeight = footerHeight ?? 0;
  const navHeightRem = `${(navHeight) / 16}rem`;
  const itemCreationRowHeightRem = `${(itemCreationRowHeight) / 16}rem`;
  const footerHeightRem = `${(footerHeight) / 16}rem`;

  // Get filter/sort data from the query param json state
  const [dashboardState, setDashboardState] = useQueryParamState(DASHBOARD_QUERY_PARAM_STATE);
  const filter = dashboardState?.filter ?? ProjectFilters.ALL as ProjectFilterType;
  const ownerIds = dashboardState?.ownerIds;
  const sortColumn = dashboardState?.sort ?? 'updatedAt';
  const sortDirection = dashboardState?.sortDirection ?? 'desc';
  const [state, dispatch] = useReducer(projectListReducer, {
    column: sortColumn,
    data: projects,
    direction: sortDirection,
    filter,
  });

  // Initialize table data when projects are fetched
  useEffect(() => {
    dispatch({
      type: 'INIT',
      data: projects
    });
  }, [projects]);

  // Update table data when filter or ownerIds dropdown changes
  useEffect(() => {
    dispatch({
      type: 'CHANGE_FILTER',
      data: projects,
      filter,
    });
  }, [filter]);

  // Functions for getting/updating sort
  const getSort = (column: string) => {
    const sort = state.column === column ? state.direction : undefined;
    if (sort === 'asc') return 'ascending';
    if (sort === 'desc') return 'descending';
    return sort;
  };

  const updateSort = (column: string) => {
    setDashboardState({
      ...dashboardState,
      sort: column,
      sortDirection: sortColumn === column ? (sortDirection === 'asc' ? 'desc' : 'asc') : (DefaultSortOrders[column] ?? 'asc')
    });
    return dispatch({ type: 'CHANGE_SORT', column });
  };

  const history = useHistory();

  const isLoading = status === 'loading';
  const nowTime = new Date().getTime();

  // Get filtered project list
  const filteredProjectList = state?.data?.filter(p => ownerIds?.length ? ownerIds.includes(p.ownerId) : true);

  return (
    <div
      style={{
        paddingBottom: `${(footerHeight) / 16}rem`,
        width: '100%'
      }}
    >
      <Grid.Row>
        <Grid.Column style={{ position: 'relative', paddingTop: '1rem' }}>
          {isLoading && <Table.Loader />}
          <div
            style={isLoading ? {
              marginTop: 0,
              minHeight: `calc(100vh - 2rem - ${navHeightRem} - ${itemCreationRowHeightRem} - ${footerHeightRem})`,
              background: 'var(--color-pure-white)'
            }: {}}
          >
            <StyledTable sortable isAdmin={permissions.isAdmin}>
              <Table.Header style={{ position: 'sticky', top: `${(navHeight + itemCreationRowHeight) / 16}rem` }}>
                <Table.Row>
                  {filter === ProjectFilters.OPEN_ITEMS && (
                    <Table.HeaderCell textAlign="left">
                      Action
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell
                    sorted={getSort('name')}
                    onClick={() => updateSort('name')}
                    textAlign={filter === ProjectFilters.OPEN_ITEMS ? 'center' : 'left'}
                  >
                    Project name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={getSort('portfolioName')}
                    onClick={() => updateSort('portfolioName')}
                    textAlign="center"
                  >
                    Portfolio
                  </Table.HeaderCell>
                  {showInvestorName && (
                    <Table.HeaderCell
                      sorted={getSort('investorName')}
                      onClick={() => updateSort('investorName')}
                      textAlign="center"
                    >
                      Investor
                    </Table.HeaderCell>
                  )}
                  {showDeveloperName && (
                    <Table.HeaderCell
                      sorted={getSort('installerName')}
                      onClick={() => updateSort('installerName')}
                      textAlign="center"
                    >
                      Developer / EPC
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell
                    sorted={getSort('statusDisplay')}
                    onClick={() => updateSort('statusDisplay')}
                    textAlign="center"
                  >
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={getSort('location')}
                    onClick={() => updateSort('location')}
                    textAlign="center"
                  >
                    Location
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={getSort('size')}
                    onClick={() => updateSort('size')}
                    textAlign="right"
                  >
                    Size (kWdc)
                  </Table.HeaderCell>
                  {permissions.isAdmin && filter !== ProjectFilters.OPEN_ITEMS && (
                    <Table.HeaderCell
                      sorted={getSort('bidDeadline')}
                      onClick={() => updateSort('bidDeadline')}
                      textAlign="center"
                    >
                      Bid deadline
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell
                    sorted={getSort('updatedAt')}
                    onClick={() => updateSort('updatedAt')}
                    textAlign="right"
                  >
                    Last update
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filteredProjectList?.map(({ id, name, portfolioName, portfolioId, investorName, installerName, statusDisplay, location, size, bidDeadline, updatedAt, isArchived, portfolioIsArchived, requiresCustomerProposal, hasOpenConversation }: any) => (
                  <Table.Row 
                    key={id} 
                    className="selectable"
                    onClick={() => {
                      return state.filter === 'OPEN_ITEMS' 
                        ? history.push(`/project/${id}/messages?navigateToSupportConversation=true`)
                        : history.push(`/project/${id}/review`);
                    }}
                  >
                    {filter === ProjectFilters.OPEN_ITEMS && (
                      <Table.Cell>
                        {requiresCustomerProposal && (
                          <Label color="olive">
                            Add proposal
                          </Label>
                        )}
                        {requiresCustomerProposal && hasOpenConversation && <br />}
                        {hasOpenConversation && (
                          <Label color="blue">
                            Open question(s)
                          </Label>
                        )}
                      </Table.Cell>
                    )}
                    <Table.Cell singleLine={false}>{name}</Table.Cell>
                    <Table.Cell textAlign="center" singleLine={false}>
                    {portfolioName &&
                      <Link 
                        to={
                          state.filter === 'OPEN_ITEMS' 
                            ? `portfolio/${portfolioId}/messages?navigateToSupportConversation=true` 
                            : `/portfolio/${portfolioId}/review`
                          } 
                        color='var(--color-pure-black)' 
                        bold 
                        onClick={(e: any) => e.stopPropagation()}
                      >
                        {portfolioName}
                      </Link>
                    }
                    </Table.Cell>
                    {showInvestorName && <Table.Cell textAlign="center" singleLine={false}>{investorName}</Table.Cell>}
                    {showDeveloperName && <Table.Cell textAlign="center" singleLine={false}>{installerName}</Table.Cell>}
                    <Table.Cell textAlign="center">
                      <ProjectStatusBadge statusDisplay={statusDisplay} bidDeadline={bidDeadline} isArchived={isArchived || portfolioIsArchived} />
                    </Table.Cell>
                    <Table.Cell textAlign="center" singleLine={false}>{location}</Table.Cell>
                    <Table.Cell textAlign="right">{formatNumber(size)}</Table.Cell> 
                    {permissions.isAdmin && filter !== ProjectFilters.OPEN_ITEMS && (
                      <Table.Cell textAlign="center" singleLine={false} style={bidDeadline && new Date(bidDeadline).getTime() < nowTime ? { color: 'var(--color-red)' } : {}}>
                        {bidDeadline ? format(new Date(bidDeadline), 'MM/dd/yyyy') : '—'}
                      </Table.Cell>
                    )}
                    <Table.Cell textAlign="right" singleLine={false}>
                      {updatedAt && format(new Date(updatedAt), 'MM/dd/yyyy')}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </StyledTable>
          </div>
        </Grid.Column>
      </Grid.Row>

      {filteredProjectList?.length === 0 && filter === '' && <EmptyTableMessage>No projects to display.</EmptyTableMessage>}
      {filteredProjectList?.length === 0 && filter !== '' && <EmptyTableMessage>No projects to display with the current filter selection.</EmptyTableMessage>}
    </div>
  );
}