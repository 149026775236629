import { Project } from '~/types/project';
import AsyncRequestButton from './AsyncRequestButton';
import { initDnvEnergyRequest, pollAsyncRequestResult } from '~/api/projects';
import toast from 'react-hot-toast';
import { useAutoSaveContext } from '~/hooks';
import useArchiveToast from '~/hooks/useArchiveToast';

export default function ProdutionEstimateButton ({ project, values, errors }: { project: Project, values: any, errors: any }) {
  const { fetchFreshData } = useAutoSaveContext() as any;

  const hasProdEstimate = values?.productionEstimates?.hasProductionEstimate;

  const { showArchiveToast } = useArchiveToast();

  return (
    <AsyncRequestButton
      apiEvent={project.apiEvents?.DNV}
      onClick={async () => {
        // Abort if archived
        if (showArchiveToast()) {
          return;
        }

        // Check for project address and/or PV array errors
        const toastErrors = [];
        if (errors.addressStreet || errors.addressCity || errors.addressState || errors.addressZip) {
          toastErrors.push('project address');
        }
        if (Object.keys(errors).some(key => key.startsWith('projectArrays'))) {
          toastErrors.push('PV array');
        }
        if (toastErrors.length) {
          toast.error(`In order to generate your production estimates with DNV, please fill out the remaining ${toastErrors.join(' and ')} fields.`, {
            duration: 6500
          });
          return;
        }
        
        try {
          return await initDnvEnergyRequest(project.id);
        } catch (err) {
          toast.error('Error initializing DNV energy request', { duration: 5000 });
          throw err;
        }
      }}
      onSuccess={() => {
        fetchFreshData().catch((err: any) => {
          console.error('Error fetching fresh data after DNV energy request', err);
          toast.error('An unexpected error occurred while fetching fresh data after DNV energy request. Please refresh the page.', { duration: 10000 });
        });
      }}
      onError={() => {
        toast.error('An error occurred fetching data from DNV. Please refresh the page and try again.');
        fetchFreshData();
      }}
      onTimeout={() => toast.error('Production estimates are taking longer than expected. Please refresh the page to check for results.')}
      text={hasProdEstimate ? 'Refresh production estimates with DNV' : 'Generate production estimates with DNV'}
      loadingText={hasProdEstimate ? 'Refreshing DNV production estimates' : 'Generating DNV production estimates'}
      pollFn={(apiEventId: string) => pollAsyncRequestResult(project.id, apiEventId)}
    />
  );
}