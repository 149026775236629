import { Header, Icon, Label, Popup } from 'semantic-ui-react';
import { useProjectContext } from '~/hooks';
import * as S from './styles';
import DataroomAccess from './DataroomAccess';
import { Button } from '~/components';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import AcceptProjectBidModal from '../../modals/AcceptProjectBid';
import DeclineProjectBidModal from '../../modals/DeclineProjectBid';
import { DealStates } from '~/constants';
import Link from '~/components/Link';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useRequiredResponseCountsQuery } from '~/requests/projects/useRequiredResponseCounts';
import { isAfter, parseISO, isSameDay } from 'date-fns';
import useArchiveToast from '~/hooks/useArchiveToast';

export default function DealSummary() {
  const { 
    project, 
    projectDisplayValues, 
    projectState,
    fetchProject 
  } = useProjectContext();
  const { openModal } = useModalContext();
  const { dealSummary } = projectDisplayValues;
  const dealState = Number(projectState.dealState);
  const { showArchiveToast } = useArchiveToast();
 
  const hasPendingBid = !!project?.bidSummary?.find((row: any) => row?.bid?.bidStatus === 'PENDING');
  const bidDeadlineDisplayText = projectDisplayValues?.investorBidArea?.bidDeadline;

  const { data: requiredResponseCountsData } = useRequiredResponseCountsQuery();

  const currentDate = new Date();
  const bidDeadlineData = (project?.bidDeadline || project?.portfolio?.bidDeadline) as string;
  const bidDeadline = parseISO(bidDeadlineData);
  const isBidDeadlinePassed = isAfter(currentDate, bidDeadline) || isSameDay(currentDate, bidDeadline);

  return (
    <S.DealSummaryContainer>
      <S.DealSummaryHeader>
        <Header as="h2">{dealSummary.headerText}</Header>
        {bidDeadlineDisplayText && !isBidDeadlinePassed ? (
          <Label color= 'olive'>
            {bidDeadlineDisplayText}
          </Label>
        ) :  null} 
      </S.DealSummaryHeader>
      {!project?.bidSummary?.length 
        ? <p style={{ margin: 'var(--small) 0' }}>Your project is being matched with investors. When they bid on this project, it will appear here.</p>
        : (
          <>
            <S.DealSummaryTable bidSummary={project.bidSummary}>

            {/* Header row */}
            <div style={{ borderLeft: 0 }} />
            {project.bidSummary.map((row: any, i: number) => 
              <S.DealSummaryInvestor key={row.id}>
                {row?.investor?.company?.name}
              </S.DealSummaryInvestor>)
            }

            {/* Dataroom Access row */}
            {!project?.portfolioId && dealState < DealStates.DATAROOM && <DataroomAccess />}

            {/* Bid Row - Title */}
            <div style={{ background: 'linear-gradient(0deg, rgba(3, 89, 140, 0.10) 0%, rgba(3, 89, 140, 0.10) 100%), #E4E4E4' }}>
              <strong>Current bid</strong>
              <div>{dealSummary.bidUnit}</div>
            </div>

            {/* Bid Row - Amounts */}
            {project.bidSummary.map((row: any, i: number) => {
              const bidAmount = dealSummary.projectBidAmounts[i];
              return <div key={`bid-${i}`} style={{ background: 'rgba(3, 89, 140, 0.05)', color: bidAmount?.color }}>
                {bidAmount?.text}
              </div>;
            })}

            {/* Comments row */}
            <div style={{ border: 'none' }} />
            {project.bidSummary.map((row: any, i: number) =>  {
              const investorId = row?.investor?.id;
              const requiredResponseCount = requiredResponseCountsData?.find((row: any) => row.investorId === investorId)?.count ?? 0;
              return (
                <S.DealSummaryComments key={`comments-${i}`}>
                  <Link to={`/project/${project.id}/messages${row?.investor?.id ? `?investorId=${row.investor.id}` : ''}`} style={{ textDecoration: 'none' }}>
                    <Icon
                      name="comment alternate outline" 
                      style={{ color: requiredResponseCount ? 'var(--color-primary)' : 'var(--color-charcoal)' }}
                   />
                 </Link>
                  {requiredResponseCount ?  (
                    <Label circular color='red'>
                      {requiredResponseCount}
                    </Label>
                  ) : null}
                </S.DealSummaryComments>
              );
            })}

            {hasPendingBid && !project?.portfolioId && (
              <>
                {/* Actions Row -- Accept bid */}
                <div style={{ border: 'none' }} />
                {project.bidSummary.map((row: any, i: number) => (
                  <div key={`actions-accept-${i}`} style={{ border: 'none' }}>
                    {row?.bid?.bidStatus === 'PENDING' && (
                      <Button 
                        size="small" 
                        primary 
                        onClick={() => {
                          if (!showArchiveToast()) {
                            openModal(AcceptProjectBidModal, { 
                              project, 
                              bid: row.bid,
                              onComplete: fetchProject 
                            });
                          }
                        }
                        }>
                        Accept
                      </Button>
                    )}
                  </div>
                ))}

                {/* Actions Row -- Decline bid */}
                <div style={{ border: 'none' }} />
                {project.bidSummary.map((row: any, i: number) =>  (
                  <div key={`actions-decline-${i}`} style={{ border: 'none' }}>
                    {row?.bid?.bidStatus === 'PENDING' && (
                      <Button
                        size="small"
                        primary
                        outlined
                        onClick={() => openModal(DeclineProjectBidModal, {
                          projectId: row?.childProjectId,
                          onComplete: fetchProject
                        })}
                      >
                        Decline
                      </Button>
                    )}
                  </div>
                ))}
              </>
            )}

            {project?.portfolioId && (
              <div style={{ gridColumn: '1/-1', display: 'flex', justifyContent: 'center', border: 'none' }}>
                <Popup
                  inverted
                  on={['hover', 'focus']}
                  position="bottom center"
                  trigger={
                    <Button
                      size="small"
                      as={ReactRouterLink}
                      to={`/portfolio/${project.portfolioId}/review`}
                      primary
                    >
                      Manage bids on portfolio page
                    </Button>
                  }
                  content="Portfolio bids are managed from the portfolio summary page. Click here to manage your bids."
                />
              </div>
            )}
            </S.DealSummaryTable>
          </>
        )
      }
    </S.DealSummaryContainer>
  );
}